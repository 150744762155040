// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-daily-rx-discounts-js": () => import("./../src/pages/daily-rx-discounts.js" /* webpackChunkName: "component---src-pages-daily-rx-discounts-js" */),
  "component---src-pages-dscsa-compliance-js": () => import("./../src/pages/DSCSA-compliance.js" /* webpackChunkName: "component---src-pages-dscsa-compliance-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("./../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-pharmacy-order-management-js": () => import("./../src/pages/pharmacy-order-management.js" /* webpackChunkName: "component---src-pages-pharmacy-order-management-js" */),
  "component---src-pages-pharmacy-payment-management-js": () => import("./../src/pages/pharmacy-payment-management.js" /* webpackChunkName: "component---src-pages-pharmacy-payment-management-js" */),
  "component---src-pages-pharmacy-to-pharmacy-marketplace-js": () => import("./../src/pages/pharmacy-to-pharmacy-marketplace.js" /* webpackChunkName: "component---src-pages-pharmacy-to-pharmacy-marketplace-js" */)
}

